import request, { API_URL, encodeQueryData } from './base';

export function handleAdminExpressJoinSession(url) {
  window.open(url, '_blank')
}

export function generateUserExpressSessionUrl({ session, sessionName, checksum }) {
  const query = encodeQueryData({ sessionName, checksum })
  return `${window.location.origin}/express-join/${session}?${query}`
}

export function handleExpressJoinSession({ sessionId, sessionName, name, checksum }) {
  const query = encodeQueryData({ session_id: sessionId, session_name: sessionName, name, checksum })
  window.open(`${API_URL}/express_join_room?${query}`)
}

export function handleJoinSession({ session, checksum }) {
  const query = encodeQueryData({ session, checksum })
  window.open(`${API_URL}/join?${query}`, '_blank')
}

/*
function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

async function waitUntilConferenceServerRunning({ user, maxAttempts = 50, delay = 2000 }) {
  for (let attempt = 1; attempt <= maxAttempts; attempt++) {
    try {
      const serverStatus = await getServerStatus({ user });
      if (serverStatus.status === 'running' && serverStatus.bbb_status === 'running') {
        return 'running';
      }
    } catch (error) {
      console.error(`Attempt ${attempt}: API call failed`, error);
    }

    console.log(`Attempt ${attempt}: Server not running, retrying in ${delay / 1000} seconds...`);
    await sleep(delay);
  }

  console.log(`Max attempts (${maxAttempts}) reached. Server is still not running.`);
  return null;
}
*/


export async function handleAdminJoinSession({ user, session }) {
  const query = encodeQueryData({ session })

  /*
  // Check if the server is on, if it is not, wait until it is ready, then open the session
  console.log('Checking server status...')
  let serverStatus = await getServerStatus({ user })
  console.log('Server status:', serverStatus)
  if (serverStatus.status !== 'running' || serverStatus.bbb_status !== 'running') {
    NotificationManager.info(
      'Encendiendo servidor de conferencias. En cuanto esté listo se abrirá la sesión.',
    )

    const response = await powerOnServer({ user })
    console.log('Response after power on:', response)
    if (response.ok) {
      NotificationManager.info(
        'El servidor del sistema de conferencias se está encendiendo',
      )
    } else {
      NotificationManager.error('Error al encender el servidor')
      return
    }

    console.log('Waiting until server is running...')
    let finalStatus = await waitUntilConferenceServerRunning({ user })
    if (finalStatus === null) {
      NotificationManager.error('Error al encender el servidor, no se pudo abrir la sesión')
      return
    }
  }
  */

  window.open(`${API_URL}/admin/join?${query}`, '_blank')
}

export async function verifyChecksum(queryParams) {
  const data = await request({
    endpoint: '/session/verify-checksum',
    method: 'GET',
    queryParams
  })
  console.log('Verify Checksum => ', data)
  if ('error' in data)
    return false
  return true
}

export async function getSession({ sessionId, checksum }) {
  return await request({
    endpoint: `/session/${sessionId}`,
    method: 'GET',
    queryParams: {
      checksum
    }
  })
}

export async function addSession({ user, sessionData }) {
  return await request({
    endpoint: '/session/',
    method: 'POST',
    user,
    body: sessionData,
  })
}

export async function editSession({ user, sessionData }) {
  const {sessionId, ...props} = sessionData
  return await request({
    endpoint: `/session/${sessionId}`,
    method: 'PUT',
    user,
    body: props,
  })
}

export async function deleteSession({ user, sessionId }) {
  return await request({
    endpoint: `/session/${sessionId}`,
    method: 'DELETE',
    user,
  })
}

export async function getPendingSessions({ user, pageSize=10, currentPage=0, sortBy=undefined, asc=true, search='' }) {
  return await request({
    endpoint: '/session/pending-sessions',
    method: 'GET',
    user,
    queryParams: {
      page_size: pageSize,
      current_page: currentPage,
      sort_by: sortBy,
      asc: asc ? 'asc' : 'desc',
      search
    }
  })
}

export async function getPreviousSessions({ user, pageSize=10, currentPage=0, sortBy=undefined, asc=true, search='' }) {
  return await request({
    endpoint: '/session/previous-sessions',
    method: 'GET',
    user,
    queryParams: {
      page_size: pageSize,
      current_page: currentPage,
      sort_by: sortBy,
      asc: asc ? 'asc' : 'desc',
      search
    }
  })
}

export async function stopSession({ sessionId, user, moderatorPassowrd}) {
  return await request({
    endpoint: `/session/stop/${sessionId}`,
    method: 'POST',
    user,
    queryParams: {
      moderator_password: moderatorPassowrd
    }
  })
}


export async function getSessionsInDateRange({ user, fromDate, toDate, search, showPaid, showPending }) {
  return await request({
    endpoint: '/session/in-date-range',
    method: 'GET',
    user,
    queryParams: {
      search,
      from_date: fromDate,
      to_date: toDate,
      show_paid: showPaid,
      show_pending: showPending
    }
  })
}

export async function getSessionPaymentDetails({ user, sessionId }) {
  return await request({
    endpoint: `/session/payment-details/${sessionId}`,
    method: 'GET',
    user
  })
}

export async function addVacation({ user, vacationData }) {
  return await request({
    endpoint: '/session/vacation',
    method: 'POST',
    user,
    body: vacationData,
  })
}

export async function deleteVacation({ user, vacationId }) {
  return await request({
    endpoint: `/session/vacation/${vacationId}`,
    method: 'DELETE',
    user,
  })
}

export async function sendEmailSession({ user, sessionId }) {
  return await request({
    endpoint: `/session/send-email/${sessionId}`,
    method: 'POST',
    user,
  })
}
