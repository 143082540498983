import React from 'react'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import { useQuery, useQueryClient } from 'react-query'
import { NotificationManager } from 'react-notifications'

import { FaMoneyBillWave, FaCloudDownloadAlt, FaTrash, FaSignInAlt, FaCopy, FaWhatsapp } from 'react-icons/fa'
import { useAuth } from '../../contexts/AuthContext'
import { dateToAltString } from '../table/PaymentsTable'
import { dateToString } from '../../services/utils'
import Confirm from '../notification/Confirm'
import {
  addPayment,
  deletePayment,
  getInvoiceReport,
  getRefundReport,
} from '../../services/payment'

import { getSessionPaymentDetails, handleAdminJoinSession, sendEmailSession } from '../../services/session'
import { AiOutlineMail } from 'react-icons/ai'

export default function SessionDetails({
  session,
  onSubmit = () => {},
  ...props
}) {
  const queryClient = useQueryClient()
  const { user } = useAuth()

  const { data: paymentDetails } = useQuery(
    ['paymentDetails', session.id],
    async () => {
      const response = await getSessionPaymentDetails({
        user,
        sessionId: session.id,
      })
      if (response.ok) {
        return response
      }
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
    },
  )

  const patientToWhatsapp = (patient) => {
    const { patient_name, patient_phone } = patient
    if (!patient_phone) {
      return undefined
    }
    const text =
      `Buenas ${patient_name}, la siguiente sesión es el ${dateToString(
        patient.date,
      )}.\n` +
      `Para acceder usa el siguiente link: ${window.location.origin}/join/${patient.id}?checksum=${patient.checksum}`
    const phone =
      patient_phone?.length > 9
        ? patient_phone.replace('+', '')
        : `34${patient_phone}`
    return `https://wa.me/${phone}?text=${encodeURIComponent(text)}`
  }

  const sessionDate = new Date((session?.date ?? '').replace(' GMT', '') ?? '')
  const tooOldSession = new Date() - sessionDate > 1000 * 60 * 60 * 12

  return (
    <Container fluid>
      <Row className="mx-2 mt-2" {...props}>
        <Col lg={12}>
          <h5>Acciones</h5>
        </Col>
      </Row>
      <Row className="mx-2">
        <Col lg={6}>
          <Button
            disabled={session.paid}
            size="sm"
            className="my-2 mx-2 btn-success emphasize-on-hover"
            onClick={async () => {
              NotificationManager.warning(
                <Confirm
                  message={
                    <span>
                      ¿Estas seguro de haber recibido el pago del paciente{' '}
                      {session.patient_name} {session.patient_last_name} del día{' '}
                      {dateToString(session.date)}?
                    </span>
                  }
                  onClick={async () => {
                    const response = await addPayment({
                      user,
                      sessionId: session.id,
                    })
                    if (response.ok) {
                      queryClient.invalidateQueries('payments')
                      queryClient.invalidateQueries('paymentDetails')
                      queryClient.invalidateQueries('incomeByMonth')
                      queryClient.invalidateQueries('calendar')
                      NotificationManager.info('Confirmación de pago realizada')
                      onSubmit()
                    }
                  }}
                />,
                null,
                100000,
              )
            }}
          >
            <FaMoneyBillWave className="mb-1" />
          </Button>
          {session.paid ? (
            <i>Ya se ha pagado esta sesión</i>
          ) : (
            <i>Marcar como pagada</i>
          )}
          <br />
          <Button
            className="mx-2 my-2 btn-modify emphasize-on-hover"
            size="sm"
            disabled={!session?.paid}
            onClick={async () => {
              if (!paymentDetails)
                return NotificationManager.error(
                  'No se han podido obtener los detalles de pago',
                )
              await getInvoiceReport({
                user,
                paymentId: paymentDetails.payment_id,
                saveAs: `F-${session.id}_${dateToAltString(
                  paymentDetails.pay_date,
                )}.pdf`,
              })
              if (paymentDetails.refund !== null) {
                await getRefundReport({
                  user,
                  paymentId: paymentDetails.payment_id,
                  saveAs: `A-${session.id}_${dateToAltString(
                    paymentDetails.pay_date,
                  )}.pdf`,
                })
              }
              onSubmit()
            }}
          >
            <FaCloudDownloadAlt className="mb-1" />
          </Button>
          {session.paid ? (
            <i>Descargar factura {paymentDetails?.refund ? 'y abono' : ''}</i>
          ) : (
            <i>Sólo se puede descargar facturas de sesiones ya pagadas</i>
          )}
          <br />
          <Button
            className="mx-2 my-2 btn-danger emphasize-on-hover"
            size="sm"
            disabled={paymentDetails?.refund}
            onClick={async () => {
              NotificationManager.warning(
                <Confirm
                  message={
                    <span>
                      ¿Estas seguro de eliminar el pago del paciente{' '}
                      {session.patient_name} {session.patient_last_name} del día{' '}
                      {dateToString(session.date)}?
                    </span>
                  }
                  onClick={async () => {
                    const paymentId =
                      paymentDetails?.payment_id === null
                        ? session.id
                        : paymentDetails.payment_id
                    const response = await deletePayment({
                      user,
                      paymentId,
                      paid: session.paid,
                    })
                    if (response.ok) {
                      queryClient.invalidateQueries('payments')
                      queryClient.invalidateQueries('paymentDetails')
                      queryClient.invalidateQueries('incomeByMonth')
                      queryClient.invalidateQueries('calendar')
                      NotificationManager.info(
                        session.paid
                          ? 'Pago eliminado correctamente'
                          : 'Sesión eliminada correctamente',
                      )
                    }
                    onSubmit()
                  }}
                />,
                null,
                100000,
              )
            }}
          >
            <FaTrash className="mb-1" />
          </Button>
          {session.paid ? (
            paymentDetails?.refund ? (
              <i>Ya se ha generado un abono</i>
            ) : (
              <i>Eliminar pago (Generará un abono)</i>
            )
          ) : (
            <i>Eliminar sesión</i>
          )}
        </Col>
        <Col lg={6}>
          <Button
            onClick={() => {
              handleAdminJoinSession({ user, session: session.id })
            }}
            onMouseDown={(e) => {
              if (e.button === 1) {
                handleAdminJoinSession({ user, session: session.id })
              }
            }}
            size="sm"
            disabled={tooOldSession}
            className="mx-2 my-1 btn-action emphasize-on-hover"
          >
            <FaSignInAlt className="mb-1" />
          </Button>
          <i>Entrar a la sesión</i>
          <br />
          <Button
            onClick={() => {
              navigator.clipboard.writeText(
                `${window.location.origin}/join/${session.id}?checksum=${session.checksum}`,
              )
              NotificationManager.info(
                'Se ha copiado el enlace al portapapeles',
              )
            }}
            disabled={tooOldSession}
            size="sm"
            className="mx-2 my-1 emphasize-on-hover"
          >
            <FaCopy className="mb-1" />
          </Button>
          <i>Copiar link de la sesión</i>
          <br />
          <Button
            href={patientToWhatsapp(session)}
            target="_blank"
            size="sm"
            className="mx-2 my-1 emphasize-on-hover btn-success"
            disabled={tooOldSession}
            onClick={() => {
              navigator.clipboard.writeText(
                `Buenas ${
                  session.patient_name
                }, la siguiente sesión es el ${dateToString(session.date)}.\n` +
                  `Para acceder usa el siguiente link: ${window.location.origin}/join/${session.id}?checksum=${session.checksum}`,
              )
              NotificationManager.info(
                'Se ha copiado el mensaje de Whatsapp al portapapeles',
              )
            }}
          >
            <FaWhatsapp className="mb-1" />
          </Button>
          <i>Copiar mensaje de Whatsapp</i>
          <br />
          <Button
            onClick={async () => {
              NotificationManager.info('Enviando correo...')
              try {
                const response = await sendEmailSession({ user, sessionId: session.id })
                if (response.ok) {
                  NotificationManager.info('Correo enviado')
                } else {
                  NotificationManager.error('Error al enviar el correo')
                }
              } catch(e) {
                NotificationManager.error('Error al enviar el correo')
              }
            }}
            size="sm"
            className="mx-2 my-1 btn-secondary-action emphasize-on-hover"
            disabled={tooOldSession}
          >
            <AiOutlineMail className="mb-1" />
          </Button>
          <i>Enviar correo</i>
        </Col>
      </Row>
    </Container>
  )
}
