import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { FaServer, FaVideo } from 'react-icons/fa'
import { AiOutlineLoading3Quarters, AiOutlineReload } from 'react-icons/ai'
import { NotificationManager } from 'react-notifications'
import { useQuery, useQueryClient } from 'react-query'

import { useAuth } from '../../contexts/AuthContext'
import Input from '../../components/material-forms/Input'
import ConferencesTable from '../../components/table/ConferencesTable'
import Confirm from '../../components/notification/Confirm'
import {
  createExpressSession,
  getServerStatus,
  powerOffServer,
  powerOnServer,
  rebootConferenceSystem,
} from '../../services/server'

export default function Server() {
  const queryClient = useQueryClient()
  const { user } = useAuth()

  const { data, status, isLoading } = useQuery(
    'serverStatus',
    async () => {
      const response = await getServerStatus({ user })
      return response
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: true,
      refetchOnMount: true,
      refetchOnReconnect: true,
    },
  )

  const isServerOn = status === 'success' && data.status === 'running'

  return (
    <Row className="mx-5 mt-5">
      <Col className="mt-4" md={{ offset: 2, span: 8 }}>
        <Row>
          <Col align="right" md={12}>
            <h5>
              {
                status === 'success' ?  (
                  <>
                    <div
                      style={{ display: 'inline-block', textAlign: 'left' }}
                    >
                      <FaServer className={`me-3 ${isServerOn ? 'text-success' : 'text-danger'}`}/> {
                        isServerOn ? 'Servidor Encendido' : 'Servidor Apagado'}<br />
                      <FaVideo className={`me-3 ${isServerOn && data.bbb_status ? 'text-success' : 'text-danger'}`}/> {
                        isServerOn && data.bbb_status === 'running' ? 'BBB Activo' : 'BBB Apagado'}
                    </div>
                    <div style={{ display: 'inline-block', verticalAlign: 'top' }} >
                      <AiOutlineReload className='ms-3 cursor-pointer rotate-on-hover' onClick={() => {
                        queryClient.invalidateQueries('serverStatus')
                      }} />
                    </div>
                  </>
                ) : (
                  status === 'error' ? (
                    <span className="text-danger">
                      <FaServer /> Error (no se pudo obtener el estado)
                    </span>
                  ) : (
                    <span>
                      <AiOutlineLoading3Quarters className='rotate me-3' /> <span className="text-secondary">Cargando estado del servidor...</span>
                    </span>
                  )
                )
              }
            </h5>
          </Col>
        </Row>
        <Row className="my-4">
          <Col md={12}>
            <ConferencesTable serverStatus={data} isLoading={isLoading} />
          </Col>
        </Row>
        <Form
          onSubmit={async (e) => {
            e.preventDefault()
            const sessionName = e.target.sessionName.value
            if (!sessionName) return
            const response = await createExpressSession({
              user,
              sessionName,
            })
            if (response.ok) {
              NotificationManager.success(
                'Sala creada correctamente, puede tardar unos segundos en aparecer.',
              )
            } else {
              NotificationManager.error('Error al crear la sala')
            }
            e.target.reset()
          }}
        >
          <Row>
            <Col md={12}>
              <h5>Crear sesión exprés</h5>
              <p className="mt-4 mx-2">
                Puedes crear una sesión exprés que no esté asociada a ningún
                paciente ni pago concreto.
                <br />
                Está pensado para usarse en caso de emergencia.
                <br />
                Una vez creada la sala te aparecerá en la lista de salas
                activas. Ahí podrás acceder a la sala y copiar el link para el
                paciente.
              </p>
            </Col>
            <Col md={12} className="mb-3">
              <Row className="justify-content-center">
                <Col xl={8} lg={10} md={11} sm={12}>
                  <Row>
                    <Col md={4} className="text-right">
                      <div className="mt-3 mx-2">
                        Nombre de a sala <span className="required">*</span>
                      </div>
                    </Col>
                    <Col md={8}>
                      <Input
                        name="sessionName"
                        type="text"
                        placeholder={'Nombre de la sala'}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col className="mt-4" md={{ offset: 4, span: 4 }}>
              <Button className="bp-btn" type="submit">
                Crear sala
              </Button>
            </Col>
          </Row>
        </Form>
        <Row className="mt-5">
          <Col md={12}>
            <h5>Reiniciar sistema de conferencias</h5>
            <p>
              Ten en cuenta que forzar un reinicio del sistema de conferencias
              expulsará a todos los usuarios de la plataforma y cerrará todas
              las sesiones abiertas.
              <br />
              Este reinicio no afectará a la web comercial ni a este manager.
            </p>
          </Col>
          <Col className="my-4" md={{ offset: 4, span: 4 }}>
            <Button
              className="btn-danger w-100 rounded-pill"
              onClick={() => {
                NotificationManager.warning(
                  <Confirm
                    message={
                      <span>
                        ¿Estas seguro de reiniciar el sistema de conferencias?
                      </span>
                    }
                    onClick={async () => {
                      const response = await rebootConferenceSystem({ user })
                      if (response.ok) {
                        NotificationManager.info(
                          'El servidor del sistema de conferencias se está apagando',
                        )
                      }
                    }}
                  />,
                  null,
                  100000,
                )
              }}
            >
              Reiniciar
            </Button>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col md={12}>
            <h5>{isServerOn ? 'Apagar' : 'Encender'} servidor de conferencias</h5>
            <p>
              Ten en cuenta que en principio no es necesario hacerlo manualmente.
              Si el servidor está apagado y creas una conferencia, se encenderá automáticamente.
              Por otro lado, el servidor se apagará automáticamente si no hay ninguna conferencia
              activa y no hay planificada ninguna sesión.
            </p>
            <p>
              Si apagas el servidor, se cerrarán todas las sesiones activas.
              <br />
              Este apagado no afectará a la web comercial ni a este manager.
            </p>
          </Col>
          <Col className="mt-4 mb-5" md={{ offset: 4, span: 4 }}>
            {
              isServerOn ? (
                <Button
                  className="btn-danger w-100 rounded-pill"
                  onClick={() => {
                    NotificationManager.warning(
                      <Confirm
                        message={
                          <span>
                            ¿Estas seguro de apagar el servidor del sistema de conferencias?
                          </span>
                        }
                        onClick={async () => {
                          const response = await powerOffServer({ user })
                          if (response.ok) {
                            NotificationManager.info(
                              'El servidor del sistema de conferencias se está apagando',
                            )
                          } else {
                            NotificationManager.error('Error al apagar el servidor')
                          }
                          queryClient.invalidateQueries('serverStatus')
                        }}
                      />,
                      null,
                      100000,
                    )
                  }}
                >
                  Apagar
                </Button>
              ) : (
                <Button
                  className="btn-success w-100 rounded-pill"
                  disabled={status === 'loading' || status === 'error'}
                  onClick={() => {
                    NotificationManager.warning(
                      <Confirm
                        message={
                          <span>
                            ¿Estas seguro de encender el sistema de conferencias?
                          </span>
                        }
                        onClick={async () => {
                          const response = await powerOnServer({ user })
                          if (response.ok) {
                            NotificationManager.info(
                              'El servidor del sistema de conferencias se está encendiendo',
                            )
                          } else {
                            NotificationManager.error('Error al encender el servidor')
                          }
                          queryClient.invalidateQueries('serverStatus')
                        }}
                      />,
                      null,
                      100000,
                    )
                  }}
                >
                  {
                    status === 'loading' ? 'Cargando...' : (status === 'error' ? 'Error' : 'Encender')
                  }
                </Button>
              )
            }
          </Col>
        </Row>
      </Col>
    </Row>
  )
}
