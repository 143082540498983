import React, { useEffect, useState } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import { useQuery, useQueryClient } from 'react-query'

import { useAuth } from '../../contexts/AuthContext'
import {
  getPendingSessions,
  handleAdminJoinSession,
} from '../../services/session'

const SECOND = 1000
const MINUTE = SECOND * 60
const HOUR = MINUTE * 60
const DAY = HOUR * 24

export default function NextSession() {
  const queryClient = useQueryClient()
  const { user } = useAuth()
  const { data, status } = useQuery(
    'nextSession',
    async () => {
      const params = {
        user,
        currentPage: 0,
        search: '',
        sortBy: undefined,
        pageSize: 1,
        asc: true,
      }

      const response = await getPendingSessions(params)
      if (response.sessions.length === 0) {
        return
      }
      return response.sessions[0]
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchInterval: false,
      refetchIntervalInBackground: false,
    },
  )
  const [currentDate, setCurrentDate] = useState(new Date())

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentDate(new Date())
    }, 1000)
    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    if (status !== 'success' || !data) return

    const timeToLaunch = currentDate - new Date(data.date.replace(' GMT', ''))
    if (timeToLaunch < 0) {
      return
    }

    // Invalidate queries when the next session is over
    const timeout = setTimeout(() => {
      queryClient.invalidateQueries('nextSession')
      queryClient.invalidateQueries('nextSessions')
      queryClient.invalidateQueries('previousSessions')
    }, timeToLaunch)

    return () => clearTimeout(timeout)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, status, currentDate])

  if (status !== 'success' || !data) {
    return <></>
  }

  const durationToStr = (duration) => {
    const hours = Math.floor(duration / 60)
    const minutes = duration % 60
    if (hours === 0) return `${minutes}m`
    else if (minutes === 0) return `${hours}h`
    return `${hours}h ${minutes}m`
  }

  const date = new Date(data.date.replace(' GMT', ''))
  const distance = date - currentDate
  const days = Math.floor(distance / DAY)
  const hours = Math.floor((distance % DAY) / HOUR)
  const minutes = Math.floor((distance % HOUR) / MINUTE)
  const seconds = Math.floor((distance % MINUTE) / SECOND)

  return (
    <Container>
      <Row className="justify-content-md-center mx-3">
        <Col
          xl={4}
          lg={6}
          className="bp-bg-light bp-text-dark my-3"
          style={{ borderRadius: '15px' }}
        >
          <Row align="center" className="mx-3 my-3">
            <h4>Accede a tu próxima sesión</h4>
            <span>
              {data.patient_name} {data.patient_last_name}
            </span>
            <br />
            {data.patient_notes && (
              <>
                <span>
                  <strong>Notas del paciente: </strong>
                  {data.patient_notes}
                </span>
                <br />
              </>
            )}
            {data.notes && (
              <>
                <span>
                  <strong>Notas de la sesión: </strong>
                  {data.notes}
                </span>
                <br />
              </>
            )}
            {data.concept !== 'Psicología' && (
              <>
                <span>
                  <strong>Concepto: </strong>
                  {data.concept}
                </span>
                <br />
              </>
            )}
            {data.duration !== 60 && (
              <>
                <span>
                  <strong>Duración: </strong>
                  {durationToStr(data.duration)}
                </span>
                <br />
              </>
            )}
            {data.payment_method !== 'Bizum' && (
              <>
                <span>
                  <strong>Método de pago: </strong>
                  {data.payment_method}
                </span>
                <br />
              </>
            )}
          </Row>
          <Row align="center" className="mx-3">
            <Col>
              {distance <= 0 ? (
                <span className="h5">La sesión ya ha comenzado</span>
              ) : (
                <>
                  {days > 0 && (
                    <>
                      <span className="h5">{days}</span>{' '}
                      <span>día{days > 1 ? 's' : ''}, </span>
                    </>
                  )}
                  {hours > 0 && (
                    <>
                      <span className="h5">{hours}</span>{' '}
                      <span>hora{hours > 1 ? 's' : ''}, </span>
                    </>
                  )}
                  <span className="h5">{minutes}</span>{' '}
                  <span>minuto{minutes !== 1 ? 's' : ''} y </span>
                  <span className="h5">{seconds}</span>{' '}
                  <span>segundo{seconds !== 1 ? 's' : ''}</span>
                </>
              )}
            </Col>
          </Row>
          <Row align="center" className="my-4 mx-md-5">
            <Col md={12}>
              <Button
                onClick={() => handleAdminJoinSession({ user, session: data.id })}
                onMouseDown={(e) => {
                  if (e.button === 1) {
                    handleAdminJoinSession({ user, sessin: data.id })
                  }
                }}
                className="bp-btn"
              >
                Entrar
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  )
}
